const EXPENSE = 'expense'
const REQUISITION = 'requisition'

const S1 = '1' //住宿标准（￥/天）
const S2 = '2' //餐补标准（￥/天）
const S3 = '3' //洗衣费          （模板配置）
const S4 = '4' //人均费用         (模板配置)
const S5 = '5' //补助金额计算
const S6 = '6' //补助金额梯度计算
const S7 = '7' //机票舱型
const S8 = '8' //火车座席
const S9 = '9' //轮船座席
const S10 = '10' //报销单金额不能超过申请单金额
const S11 = '11' //开关规则(开的时候才能提交)

export const FORM_TYPES = {
  EXPENSE: EXPENSE, //报销单
  REQUISITION: REQUISITION //申请单
}

export const FORM_TYPE_NAMES = {
  [EXPENSE]: i18n.get('报销单'),
  [REQUISITION]: i18n.get('申请单')
}

export const FORM_TYPE_FIELD_NAMES = {
  [EXPENSE]: i18n.get('报销字段'),
  [REQUISITION]: i18n.get('申请字段')
}

export const ExpenseStandardEditColums = {
  ENUMS: "ENUMS"
}

export const STANDARD_TEMPLATE_CODES = {
  S1: S1,
  S2: S2,
  S3: S3,
  S4: S4,
  S5: S5,
  S6: S6,
  S7: S7,
  S8: S8,
  S9: S9
}

export const NO_CITY_GRADE_STANDARD_TEMPLATE_CODES = [
  STANDARD_TEMPLATE_CODES.S7,
  STANDARD_TEMPLATE_CODES.S8,
  STANDARD_TEMPLATE_CODES.S9
]

export const TRANSPORT_STANDARD_TEMPLATE_CODES = [
  STANDARD_TEMPLATE_CODES.S7,
  STANDARD_TEMPLATE_CODES.S8,
  STANDARD_TEMPLATE_CODES.S9
]

export const NO_ALLOW_SUBMIT_STANDARD_TEMPLATE_CODES = [STANDARD_TEMPLATE_CODES.S5, STANDARD_TEMPLATE_CODES.S6]

export const FILED_CHOOSE_TEXT = {
  [S7]: [
    i18n.get('舱位'),
    i18n.get('航班舱型'),
    i18n.get('「舱位」需要配合枚举航班舱型字段，系统才能在员工录入时校验舱位标准')
  ],
  [S8]: [
    i18n.get('坐席'),
    i18n.get('火车席别'),
    i18n.get('「坐席」需要配合枚举火车席别字段，系统才能在员工录入时校验舱位标准')
  ],
  [S9]: [
    i18n.get('轮船'),
    i18n.get('轮船舱型'),
    i18n.get('「轮船」需要配合枚举轮船舱型字段，系统才能在员工录入时校验舱位标准')
  ],
  [S1]: [
    i18n.get('酒店金额标准'),
    i18n.get('日期范围'),
    i18n.get(
      '「酒店金额标准」需要配合金额和日期范围字段，系统才能在员工录入时校验金额标准，公式为金额÷天数。其中金额匹配预置的「金额」字段，天数需指定一个「日期范围」字段，计算方式为结束日期减去起始日期，如5.1 ~ 5.3，天数为2天。'
    )
  ],
  [S2]: [
    i18n.get('补助金额标准'),
    i18n.get('日期范围'),
    i18n.get(
      '「补助金额标准」需要配合金额和日期范围字段，系统才能在员工录入时校验金额标准，公式为金额÷天数。其中金额匹配预置的「金额」字段，天数需指定一个「日期范围」字段，计算方式为结束日期减去起始日期，如5.1 ~ 5.3，天数为3天。'
    )
  ],
  [S5]: [
    i18n.get('补助金额计算'),
    i18n.get('日期范围'),
    i18n.get(
      '「补助金额计算」需要配合金额和日期范围字段，系统才能在员工录入时自动计算金额，公式为「金额=金额标准×天数」。其中金额匹配预置的「金额」字段，天数需指定一个「日期范围」字段，计算方式为结束日期减去起始日期，如5.1 ~ 5.3，天数为3天。'
    )
  ],
  [S6]: [
    i18n.get('补助金额梯度计算'),
    i18n.get('日期范围'),
    i18n.get(
      '「补助金额梯度计算」需要配合金额和日期范围字段，系统才能在员工录入时自动计算金额，公式为「金额=金额标准1×设置天数 +  金额标准2×（ 天数 - 设置天数 ）。其中金额匹配预置的「金额」字段，天数需指定一个「日期范围」字段，计算方式为结束日期减去起始日期，如5.1 ~ 5.3，天数为3天。'
    )
  ],
  default: [i18n.get('未知类型'), i18n.get('未知字段'), i18n.get('需要选择一个字段，系统才能在员工录入时校验标准')]
}
