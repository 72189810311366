// @i18n-ignore-all
import { app as api } from '@ekuaibao/whispered';
import {
  getStandardActiveList,
  getStandardDisabledList,
  getDetailsByVersionedId,
  changeStandardTitle,
  searchCity,
  updateStandardCurrency,
} from './standard-action';
import { FORM_TYPES } from './const';
import loadable from '@loadable/component';

export default [
  {
    id: '@expense-standard',
    path: '/expense-standard',
    ref: '/',
    onload: () => import('./expense-standard-view'),
    reducer: () => import('./standard-reducer'),
    dependencies: ['@auth-check'],
    'standard:active:list': (param) => api.dispatch(getStandardActiveList(param)),
    'standard:disabled:list': (param) => api.dispatch(getStandardDisabledList(param)),
    'updata:standard:list': () =>
      Promise.all([
        api.dispatch(getStandardActiveList({ active: true })),
        api.dispatch(getStandardDisabledList({ active: false })),
      ]),
    'get:details:by:versionId': (versionId) => api.dispatch(getDetailsByVersionedId(versionId)),
    'change:standard:title': (param) => api.dispatch(changeStandardTitle(param)),
    'get:standard:city': (params) => api.dispatch(searchCity(params)),
    'import:FORM_TYPES': () => FORM_TYPES,
    'update:expense:currency': (params) => api.dispatch(updateStandardCurrency(params)),
  },
  {
    point: '@@layers',
    prefix: '@expense-standard',
    onload: () => import('./layers'),
  },
  {
    point: '@@menus',
    onload: () => [
      {
        id: 'expense-standard',
        pId: 'enterprise-manage',
        permissions: ['SYS_ADMIN'],
        weight: 12,
        label: '费用标准',
        href: '/expense-standard',
        icon: 'contacts',
      },
    ],
  },
  {
    resource: '@expense-standard',
    value: {
      ['const']: require('./const'),
      ['util']: require('./util'),
      ['elements/DimensionInput']: loadable(() => import('./elements/DimensionInput')),
    },
  },
];
