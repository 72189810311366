/**
 * Created by xinailiu on 17/6/28.
 */
import key from './key';
import { Resource } from '@ekuaibao/fetch';

const enumList = new Resource('/api/v1/basedata');
const rules = new Resource('/api/v1/rule');
const specifications2 = new Resource('/api/form/v2/specifications');
const feeTypeRes = new Resource('/api/v1/form/feeTypes');
const costcontrol = new Resource('/api/costcontrol/v2');
const city = new Resource('/api/v1/basedata/city');
const excel = new Resource('/api/costcontrol/v2/excel');
import { getDimentions } from './action.helper';

export function getEnumList() {
  return {
    type: key.GET_ENUM_LIST,
    payload: enumList.GET('/enums', ''),
  };
}

export function getTrainEnumListByCode(code) {
  return {
    type: key.GET_ENUM_LIST_TRAIN,
    payload: enumList.GET('/enumItems/byEnumCode/$code', { code }),
  };
}

export function getAirplaneEnumListByCode(code) {
  return {
    type: key.GET_ENUM_LIST_AIRPLANE,
    payload: enumList.GET('/enumItems/byEnumCode/$code', { code }),
  };
}

export function getShipEnumListByCode(code) {
  return {
    type: key.GET_ENUM_LIST_SHIP,
    payload: enumList.GET('/enumItems/byEnumCode/$code', { code }),
  };
}

export function addRule(data) {
  return {
    type: key.ADD_RULE,
    payload: rules.POST('/rules', data),
  };
}

export function getTemplates() {
  return {
    type: key.GET_TEMPLATE_LIST,
    payload: rules.GET('/templates'),
  };
}

export function getCityGroup() {
  return {
    type: key.GET_CITY_GROUP_LIST,
    payload: rules.GET('/cityGrades'),
  };
}

export function getCityGroupDetail() {
  return {
    type: key.GET_CITY_GROUP_DETAIL_LIST,
    payload: rules.GET('/cityGrades/detail'),
  };
}

export function getRuleList(filters, page, orderByDesc = true) {
  let { showNotAcive, keyword } = filters;
  let { start, count } = page;
  let join = 'lastChangeLog.staffId,staffId,/v1/organization/staffs';
  let filter = `createApproach.in("CONFIGURATION")`;
  keyword ? (filter = `${filter}&&(name.contains("${keyword}"))`) : null;
  !showNotAcive ? (filter = `${filter}&&active == true`) : null;
  start = start ? start : 0;
  count = count ? count : 10;
  let order;
  if (orderByDesc) {
    order = 'desc(createTime)';
  } else {
    order = 'asc(createTime)';
  }

  return {
    type: key.GET_RULE_LIST,
    payload: rules.POST('/rules/search', null, { start, count, join, filter, order }),
  };
}

export function enableRule(id) {
  return {
    type: key.ENABLE_RULE,
    payload: rules.PUT('/rules/$id/restore', { id }),
  };
}

export function disableRule(id) {
  return {
    type: key.DISABLE_RULE,
    payload: rules.PUT('/rules/$id/disable', { id }),
  };
}

export function deleteRule(id) {
  return {
    type: key.DELETE_RULE,
    payload: rules.DELETE('/rules/$id', { id }),
  };
}

export function editRule(data) {
  return {
    type: key.EDIT_RULE,
    payload: rules.PUT('/rules/$id', data),
  };
}

export function getLogs(id) {
  let data = {
    id: id,
    join: 'staffId,staffId,/v1/organization/staffs',
  };
  return {
    type: key.GET_LOGS,
    payload: rules.GET('/changeLogs/byRuleId/$id', data),
  };
}

export function saveCityGroup(data) {
  return {
    type: key.SAVE_CITY_GROUP,
    payload: rules.PUT('/cityGrades/detail', data),
  };
}

export function getFullFeeTypes() {
  let params = {
    join: 'id,feetypeTemplate,/form/v2/specifications',
  };
  return {
    type: key.GET_FULL_FEETYPE_LIST,
    payload: feeTypeRes.GET('/tree', params),
  };
}

export function saveFeeType(data, done) {
  let params = { ...data };
  return {
    type: key.SAVE_FEETYPE,
    payload: feeTypeRes.PUT('/$id', params),
    done,
  };
}

export function getFeetypeTemplateById(id, done) {
  return {
    type: key.GET_FEETYPE_TEMPLATE,
    payload: specifications2.GET('/feeType/[id]', { id }),
    done,
  };
}

export function getStandardActiveList(data) {
  const { active, displayAll, cumulative = false } = data;
  const param = active !== undefined ? { active: active ? 'enabled' : 'disabled', cumulative } : {};
  displayAll && (param.displayAll = displayAll);
  return {
    type: key.GET_NEW_STANDARD_LIST,
    payload: costcontrol.GET('/search', param),
  };
}

export function getStandardDisabledList(active) {
  return {
    type: key.GET_DISABLED_STANDARD_LIST,
    payload: costcontrol.GET('/search', { active }),
  };
}

export function getStandardGlobalSetting() {
  return {
    type: key.GET_GLOBAL_SETTING,
    payload: costcontrol.GET('/globalsetting'),
  };
}

export function saveStandardGlobalSetting(dateDimension) {
  return {
    type: key.SAVE_GLOBAL_SETTING,
    payload: costcontrol.PUT('/globalsetting', dateDimension),
  };
}

export function standardIsActive(params) {
  const { id, active } = params;
  return {
    type: key.STANDAER_ISACTIVE,
    payload: costcontrol.PUT('/config/active/$id', { id }, { active }),
  };
}

export function getVersionsById(configId) {
  return {
    type: key.GET_VERSIONS_BYID,
    payload: costcontrol.GET('/config/versions', { configId }),
  };
}

export function getOldStandardDetail(id) {
  let data = {
    id: id,
    join: 'lastChangeLog.staffId,staffId,/v1/organization/staffs',
  };
  const request = rules
    .POST('/rules/$id/detail', null, data)
    .then((values) => getDimentions(values,'value.legalEntityIds'));
  return {
    type: key.GET_OLD_STANDARD_DETAIL,
    payload: request,
  };
}

export function createStandard(data) {
  data.period = data.isPeriod;
  delete data.isPeriod;
  return {
    type: key.CREATE_STANDARD,
    payload: costcontrol.POST('/config', data),
  };
}

export function getDetailsByVersionedId({ versionedId }) {
  const request = costcontrol
    .GET('/config/version/$versionedId', { versionedId })
    .then(getDimentions);
  return {
    type: key.GET_DETAILS_BY_VERSIONID,
    payload: request,
  };
}
// 编辑费用标准
export function updateVersion(data) {
  data.period = data.isPeriod;
  delete data.isPeriod;
  return {
    type: key.UPDATE_VERSION,
    payload: costcontrol.PUT('/config/version/$versionId', data),
  };
}
//修订费用标准
export function revisionVersion(data) {
  data.period = data.isPeriod;
  delete data.isPeriod;
  return {
    type: key.UPDATE_VERSION,
    payload: costcontrol.POST('/config/$configId/version/$versionedId', data),
  };
}

export function deleteVersion(versionId) {
  return {
    type: key.DELETE_VERSION,
    payload: costcontrol.DELETE('/config/version/$versionId', { versionId }),
  };
}

export function changeStandardTitle(params) {
  const { name, id } = params ? params : {};
  const param = id ? { payload: costcontrol.PUT('/config/base/$id', { id, name }) } : {};
  return {
    type: key.CHANGE_STANDARD_TITLE,
    title: name,
    ...param,
  };
}

export function modifyEffectiveTime({ versionedId, effectiveTime }) {
  return {
    type: key.MODIFY_STANDARD_EFFECTIVE_TIME,
    payload: costcontrol.PUT(
      '/config/version/time/$versionedId',
      { versionedId },
      { effectiveTime },
    ),
  };
}

export function searchCity(param) {
  const params = { ...param, start: 0, count: 20 };
  return {
    type: key.GET_CITY,
    payload: city.GET('/search', { ...params }),
  };
}

export function getExcelImportUrl(data) {
  return {
    type: key.GET_EXCEL_IMPORT_URL,
    payload: excel.POST('/export', data),
  };
}

export function updateStandardCurrency(params) {
  return {
    type: key.UPDATE_STANDARD_CURRENCY,
    payload: params,
  };
}

