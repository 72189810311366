export const ID = '@expense-standard';

export default {
  ID: ID,
  GET_ENUM_LIST: `${ID}/GET_ENUM_LIST`,
  GET_ENUM_LIST_TRAIN: `${ID}/GET_ENUM_LIST_TRAIN`,
  GET_ENUM_LIST_AIRPLANE: `${ID}/GET_ENUM_LIST_AIRPLANE`,
  GET_ENUM_LIST_SHIP: `${ID}/GET_ENUM_LIST_SHIP`,
  ADD_RULE: `${ID}/ADD_RULE`,
  GET_TEMPLATE_LIST: `${ID}/GET_TEMPLATE_LIST`,
  GET_CITY_GROUP_LIST: `${ID}/GET_CITY_GROUP_LIST`,
  GET_CITY_GROUP_DETAIL_LIST: `${ID}/GET_CITY_GROUP_DETAIL_LIST`,
  GET_RULE_LIST: `${ID}/GET_RULE_LIST`,
  DISABLE_RULE: `${ID}/DISABLE_RULE`,
  ENABLE_RULE: `${ID}/ENABLE_RULE`,
  DELETE_RULE: `${ID}/DELETE_RULE`,
  EDIT_RULE: `${ID}/EDIT_RULE`,
  GET_LOGS: `${ID}/GET_LOGS`,
  SAVE_FEETYPE: `${ID}/SAVE_FEETYPE`,
  SAVE_CITY_GROUP: `${ID}/SAVE_CITY_GROUP`,
  GET_FULL_FEETYPE_LIST: `${ID}/GET_FULL_FEETYPE_LIST`,
  GET_FEETYPE_TEMPLATE: `${ID}/GET_FEETYPE_TEMPLATE`,
  GET_NEW_STANDARD_LIST: `${ID}/GET_NEW_STANDARD_LIST`,
  GET_GLOBAL_SETTING: `${ID}/GET_GLOBAL_SETTING`,
  SAVE_GLOBAL_SETTING: `${ID}/SAVE_GLOBAL_SETTING`,
  STANDAER_ISACTIVE: `${ID}/STANDAER_ISACTIVE`,
  GET_VERSIONS_BYID: `${ID}/GET_VERSIONS_BYID`,
  GET_DISABLED_STANDARD_LIST: `${ID}/GET_DISABLED_STANDARD_LIST`,
  GET_OLD_STANDARD_DETAIL: `${ID}/GET_OLD_STANDARD_DETAIL`,

  CREATE_STANDARD: `${ID}/CREATE_STANDARD`,
  GET_DETAILS_BY_VERSIONID: `${ID}/GET_DETAILS_BY_VERSIONID`,
  UPDATE_VERSION: `${ID}/UPDATE_VERSION`,
  DELETE_VERSION: `${ID}/DELETE_VERSION`,
  CHANGE_STANDARD_TITLE: `${ID}/CHANGE_STANDARD_TITLE`,
  MODIFY_STANDARD_EFFECTIVE_TIME: `${ID}/MODIFY_STANDARD_EFFECTIVE_TIME`,
  GET_EXCEL_IMPORT_URL: `${ID}/GET_EXCEL_IMPORT_URL`,
  GET_CITY: `${ID}/GET_CITY`,
  UPDATE_STANDARD_CURRENCY: `${ID}/UPDATE_STANDARD_CURRENCY`,
};
