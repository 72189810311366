/**
 *  Created by pw on 2020/9/23 9:35 下午.
 */
import { __awaiter, __generator, __read } from "tslib";
import { app } from '@ekuaibao/whispered';
import { set, get } from 'lodash';
export function getDimentions(value, path) {
    var _a;
    if (path === void 0) { path = 'value.controlConfigVersioned.legalEntityIds'; }
    return __awaiter(this, void 0, Promise, function () {
        var legalEntityIds, items, _b, dimention, baseCurrencyId, currency;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    legalEntityIds = get(value, path, []);
                    if (!legalEntityIds.length) return [3 /*break*/, 4];
                    return [4 /*yield*/, app.invokeService('@custom-dimension:get:DimensionItemDetail', {
                            id: legalEntityIds,
                        })];
                case 1:
                    items = (_c.sent()).items;
                    set(value, path, items);
                    _b = __read(items, 1), dimention = _b[0];
                    baseCurrencyId = (_a = dimention === null || dimention === void 0 ? void 0 : dimention.form) === null || _a === void 0 ? void 0 : _a.baseCurrencyId;
                    if (!(baseCurrencyId === null || baseCurrencyId === void 0 ? void 0 : baseCurrencyId.length)) return [3 /*break*/, 3];
                    return [4 /*yield*/, app.invokeService('@currency-manage:get:currency:info:by:id', {
                            id: baseCurrencyId,
                        })];
                case 2:
                    currency = (_c.sent()).value;
                    app.invokeService('@expense-standard:update:expense:currency', { currency: currency });
                    _c.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    app.invokeService('@expense-standard:update:expense:currency', { currency: null });
                    _c.label = 5;
                case 5: return [2 /*return*/, value];
            }
        });
    });
}
