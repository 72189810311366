// "zZU5Rjr+Jk1k00_1",   住宿费分地区每间夜金额控制
// "zZU5Rjr+Jk1k00_2",   餐补费分地区每日金额控制
// "zZU5Rjr+Jk1k00_3",   出行时间超过5天4夜可以填写洗衣费
// "zZU5Rjr+Jk1k00_4",   根据费用上填写的人数，人均不能超过xxx
// "zZU5Rjr+Jk1k00_5",   差补
// "zZU5Rjr+Jk1k00_6",   阶梯差补
// "zZU5Rjr+Jk1k00_7",   机票舱型
// "zZU5Rjr+Jk1k00_8"    火车座席

//----------获取标准列表接口------------
//createApproach=SPECIFICATION //获取模板上配置的标准
//createApproach=CONFIGURATION //获取在配置界面配置的标准
//没有createApproach参数表示获取所有标准
import { app as api } from '@ekuaibao/whispered'
import { oldStandardTypeFun } from '@ekuaibao/lib/lib/lib-util'
export const AIPLANE = 'CabinType' //航班舱型code
export const TRAIN = 'TrainSeatType' //火车票席别code
export const SHIP = 'CruiseCabinType' //轮船席别code

export const S1 = '1' //住宿标准（￥/天）
export const S2 = '2' //餐补标准（￥/天）
export const S3 = '3' //洗衣费          （模板配置）
export const S4 = '4' //人均费用         (模板配置)
export const S5 = '5' //补助金额计算
export const S6 = '6' //补助金额梯度计算
export const S7 = '7' //机票舱型
export const S8 = '8' //火车座席
export const S9 = '9' //轮船座席
export const S10 = '10' //限制「报销总额」不得大于申请单「申请金额」

import moment from 'moment'

//CREATE  //创建
//UPDATE  //修改
//DISABLE //停用
//RESTORE //停用
export function getLogType(type) {
  switch (type) {
    case 'CREATE':
      return i18n.get('创建')
      break
    case 'UPDATE':
      return i18n.get('修改')
      break
    case 'DISABLE':
      return i18n.get('停用')
      break
    case 'RESTORE':
      return i18n.get('启用')
      break
  }
}

export const StandardMenuMap = {
  7: i18n.get('机票标准'),
  1: i18n.get('酒店标准'),
  8: i18n.get('火车标准'),
  5: i18n.get('补助标准'),
  9: i18n.get('轮船标准')
}

export function getTempContentByTempId(data, cityGroups, trainEnumList, airplaneEnumList, shipEnumList) {
  let tempId = data.templateIds[0]
  let isCityOpen = data.useCityGrade
  let ruleItems = data.ruleItems
  switch (tempId) {
    case S1:
    case S2:
      let value = []
      if (isCityOpen) {
        ruleItems.forEach(item => {
          value.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) +
              i18n.get('：') +
              getThresholdsS1S2(item.thresholds, cityGroups)
          )
        })
      } else {
        ruleItems.forEach(item => {
          value.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) + i18n.get('：') + item.thresholds[0].value
          )
        })
      }

      return value.map(line => line).join(';')
    case S3:
      break
    case S4:
      break
    case S5:
      let value3 = []
      if (isCityOpen) {
        ruleItems.forEach(item => {
          value3.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) +
              i18n.get('：') +
              getThresholdsS5(item.thresholds, cityGroups)
          )
        })
      } else {
        ruleItems.forEach(item => {
          value3.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) +
              i18n.get('：') +
              i18n.get('出差天数') +
              '*' +
              item.thresholds[0].value
          )
        })
      }

      return value3.map(line => line).join(';')
    case S6:
      let value4 = []
      if (isCityOpen) {
        ruleItems.forEach(item => {
          value4.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) +
              i18n.get('：') +
              getThresholdsS6(item.thresholds, cityGroups)
          )
        })
      } else {
        ruleItems.forEach(item => {
          value4.push(
            item.scope_local.map(line => line.name).join(i18n.get('、')) +
              i18n.get(`：{__k0}*{__k1}+{__k2}*(出差天数-{__k3})`, {
                __k0: item.thresholds[0].value[0].threshold,
                __k1: item.thresholds[0].value[1].stile,
                __k2: item.thresholds[0].value[1].threshold,
                __k3: item.thresholds[0].value[1].stile
              })
          )
        })
      }

      return value4.map(line => line).join(';')
    case S7:
      return getValueS7S8S9(ruleItems, airplaneEnumList)
    case S8:
      return getValueS7S8S9(ruleItems, trainEnumList)
    case S9:
      return getValueS7S8S9(ruleItems, shipEnumList)
  }
}

function getThresholdsS1S2(thresholds, cityGroup) {
  let text = []
  cityGroup.forEach(line => {
    thresholds[0].value.forEach(result => {
      let value = result[line.id]
      if (value || value === 0) {
        text.push(line.name + ' ' + value)
        return
      }
    })
  })
  return text.map(line => line).join(',')
}

function getThresholdsS5(thresholds, cityGroup) {
  let text = []
  cityGroup.forEach(line => {
    thresholds[0].value.forEach(result => {
      let value = result[line.id]
      if (value) {
        text.push(line.name + ' ' + i18n.get('出差天数') + '*' + value)
        return
      }
    })
  })
  return text.map(line => line).join(',')
}

function getThresholdsS6(thresholds, cityGroup) {
  let text = []
  cityGroup.forEach(line => {
    thresholds[0].value.forEach(result => {
      let value = result[line.id]
      if (value) {
        let thresholdText =
          ` ${value[0].threshold}*${value[1].stile}+${value[1].threshold}*(` +
          i18n.get('出差天数') +
          `-${value[1].stile})`
        text.push(line.name + thresholdText)
        return
      }
    })
  })
  return text.map(line => line).join(',')
}

function getThresholdsS7S8(thresholds, enumList) {
  let text = []
  thresholds[0].value.map(line => {
    let enumItem = enumList.filter(item => item.code === line)
    if (enumItem && enumItem.length > 0) {
      text.push(enumItem[0].name)
    }
  })
  return text.map(line => line).join(',')
}

function getValueS7S8S9(ruleItems, list) {
  let value2 = []
  ruleItems.forEach(item => {
    value2.push(
      item.scope_local.map(line => line.name).join(i18n.get('、')) +
        i18n.get('：') +
        getThresholdsS7S8(item.thresholds, list)
    )
  })
  return value2.map(line => line).join(';')
}

export function getDeptItemsByIds(list = [], ids) {
  let items = []
  let fn = item => {
    if (ids.indexOf(item.id) > -1) {
      items.push(item)
    }

    item.children = item.children || []
    if (item.children.length) {
      item.children.forEach(c => {
        fn(c)
      })
    }
  }

  list.forEach(item => {
    fn(item)
  })
  return items
}

export function getItemByIds(data = [], ids = []) {
  if (data.length === 0 || ids.length === 0) return []
  return data.filter(line => {
    return ids.indexOf(line.id) > -1
  })
}

export function getGlobelDateFields() {
  let dataFieldsMap = {}
  const globalFields = api.getState('@common').globalFields.data
  const blackFieldList = ['lastPrintTime']
  let dateField = globalFields.filter(line => {
    const { dataType } = line
    return (
      line.name !== 'payDate' &&
      dataType &&
      (dataType.type === 'date' || dataType.type === 'dateRange') &&
      blackFieldList.indexOf(line.name) < 0
    )
  })
  let cDateField = dateField.slice()
  let dataFields = cDateField.map(line => {
    dataFieldsMap[line.name] = line
    return { ...line, value: line.name }
  })
  return { dataFields, dataFieldsMap }
}

export const StandardTypeChange = {
  ...oldStandardTypeFun(),
  COMPLEMENT_MONEY_AUTO_CACL: i18n.get(`补助金额自动计算`),
  TIMES: i18n.get(`按次控制`),
  NIGHT: i18n.get(`按天控制`),
  DAY: i18n.get(`按天控制`),
  WEEK: i18n.get(`按周控制`),
  MONTH: i18n.get(`按月控制`),
  QUARTER: i18n.get(`按季度控制`),
  HALF_A_YEAR: i18n.get(`按半年控制`),
  YEAR: i18n.get(`按年控制`)
}

export const fieldTypes = ['FEETYPE', 'STAFF', 'DEPARTMENT', 'CITY', 'MONEY']

export const costInvalidTime = 4124872347000

export function getInvalidTimeStr(invalidTime, formatStr = 'YYYY/MM/DD') {
  return invalidTime !== costInvalidTime ? `~${moment(invalidTime).format('YYYY/MM/DD')}` : ''
}
